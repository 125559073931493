import React from "react";
import classNames from "classnames"
import PropTypes from "prop-types"
import Headroom from "react-headroom"
import MaxWidth from "components/_ui/MaxWidth/MaxWidth"
import Link from "components/_ui/Link/Link"
import Logo from "components/_ui/Logo/Logo"
import "./Header.scss";

const Header = ({ knockoutHeader }) => (
    <Headroom className="Header__headroom">
        <MaxWidth size="l" className={classNames("Header__container", {
            "Header__container--knockoutColors" : knockoutHeader
        })}>
            <div className="Header__content">
                <Link className="Header__logo" to="/">
                    <h3>🧙‍♂️</h3>
                </Link>
                <div className="Header__links">
                    <Link
                        className="Header__link"
                        to={'/#work'}
                    >
                        Work
                    </Link>
                    <Link
                        className="Header__link"
                        // to={'/blog'}
                        doOpenInNewTab
                        to="https://ahadsheriff.medium.com/"
                    >
                        Writing
                    </Link>
                    <Link
                        doOpenInNewTab
                        className="Header__link Header__link--email"
                        to="https://www.dropbox.com/s/9i90j0i26q3pte9/ahadsheriff_resume.pdf?dl=0"
                    >
                        Resume
                        <span>&#8594;</span>
                    </Link>
                </div>
            </div>
        </MaxWidth>
    </Headroom>
)

Header.propTypes = {
    knockoutHeader: PropTypes.bool,
}

Header.defaultProps = {
    knockoutHeader: false,
}

export default Header